.header-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 40px;
  position: sticky;
  left: 0;
  top: 0;
  background: white;
  z-index: 1000;

  .section-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .logo-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 1024px) {
        width: 100%;
      }

      .logo-section {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 800;
        letter-spacing: 0.1em;
        color: #2e3035;

        img {
          width: 48px;
          height: 48px;
          margin-right: 10px;
        }
      }
    }

    .navbar-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .navbar-section {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;

        .navbar-list {
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 40px;
          font-family: "Montserrat", sans-serif;

          a {
            font-weight: 600;
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 0.1em;
          }

          a::after {
            content: "";
            width: 0;
            height: 1px;
            display: block;
            background: grey;
            transition: 300ms;
          }

          a:hover::after {
            width: 100%;
          }
        }
      }

      .navbar-section:not(:last-child) {
        justify-content: flex-start;
      }

      .language-items {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 600;

        .line {
          width: 2px;
          height: 20px;
          background: #000;
          margin: 0 10px;
        }
      }

      @media screen and (max-width: 1024px) {
        display: none;
        transition: all ease-in-out 0.2s;
      }
    }

    .burger-wrapper {
      display: none;
      @media screen and (max-width: 1024px) {
        display: flex;
        transition: all ease-in-out 0.2s;
      }
    }
  }
}
