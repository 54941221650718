@keyframes slideAnimation {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  .looper {
    width: 100%;
    overflow: hidden;
  }
  
  .looper__innerList {
    display: flex;
    justify-content: center;
    width: fit-content;
  }
  
  .looper__listInstance {
    display: flex;
    width: max-content;
    column-gap: 40px;
    padding: 0 20px;
    animation: slideAnimation linear infinite;
  }