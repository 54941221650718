.menu-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  box-sizing: border-box;
}

.nav-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  height: 100%;

  .navbar-section {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;

    a {
      text-transform: uppercase;
    }

    a:not(:last-child) {
      margin-bottom: 28px;
    }
  }
}
