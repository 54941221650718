.contact-page {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 120px;

    .contact-form-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        padding: 80px;
        box-sizing: border-box;

        @media screen and (max-width:912px) {
                justify-content: center;
            }


        background: url('https://res.cloudinary.com/droimgtqi/image/upload/v1681339632/republica-textil/group-58_pvwinm.webp');
        width: 100%;
        background-position: top center;
        background-size: cover;
        background-repeat: no-repeat;

        .contact-form {
            width: auto;
            margin-right: 60px;
            @media screen and (max-width:912px) {
                margin-right: 0;
            }
        }

        form {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            width: 100%;

            @media screen and (max-width: 1024px) {
                align-items: center;
            }

            .formInput {
                display: flex;
                width: 100%;
                flex-direction: column;
                height: 60px;
                box-sizing: border-box;

                input {
                    width: 100%;
                    border: 0;
                    outline: none;
                    background: transparent;
                    color: #2e3035;
                    border-bottom: 1px solid #afb1b7;

                    @media screen and (max-width: 912px) {
                        width: 100%;
                    }
                }

                input:focus {
                    outline: none;
                    color: #2e3035;
                }

                input::placeholder {
                    color: #afb1b7;
                }

                input:invalid[focused="true"] {
                    border-bottom: 1px solid red;
                }

                input:invalid[focused="true"]~span {
                    font-size: 12px;
                    color: red;
                    display: block;
                }
            }
        }

        .err-msg {
            font-size: 12px;
            padding-top: 3px;
            color: red;
            display: none;
            margin-bottom: 8px;
        }

    }
}