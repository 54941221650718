.about-wrapper {
    width: 100%;
    margin-bottom: 180px;
    padding: 48px;
    box-sizing: border-box;

    .story-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @media screen and (max-width:912px) {
            flex-direction: column;
            text-align: center;
        }

        .left-side {
            margin: 0 18px;

            @media screen and (max-width:912px) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 0;
                margin-bottom: 24px;
            }
        }

        .right-side {
            margin: 0 18px;

            @media screen and (max-width:912px) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 0;
                margin-bottom: 24px;
            }
        }
    }

    .story-wrapper:not(:last-child) {
        margin-bottom: 140px;
    }
}

.img-about {
    width: 400px;
    margin-bottom: 24px;

    @media screen and (max-width:912px) {
        width: 300px;
    }
}

.text-wrapper {
    max-width: 600px;

    @media screen and (max-width:1024px) {
        max-width: 400px;
    }
}