.big-title {
  font-size: 48px;
  font-weight: 700;
}

.primary-btn {
  display: flex;
  border-radius: 100px;
  border: 2px solid black;
  background: linear-gradient(to right, white 50%, black 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
  font-weight: bold;
  width: 240px;
  height: 40px;
  cursor: pointer;

  div {
    display: flex;
    color: #fff;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: all 0.6s ease-out;
  }

  div:hover {
    color: black;
  }
}

.primary-btn:hover {
  background-position: left bottom;
}

.secondary-button {
  border-radius: 100px;
  border: 2px solid #fff;
  background: linear-gradient(to right, white 50%, black 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
  color: #fff;
  font-weight: bold;
  width: 280px;
  height: 40px;
  cursor: pointer;

  div {
    display: flex;
    color: #fff;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: all 0.6s ease-out;
  }

  div:hover {
    color: black;
  }
}

.secondary-button:hover {
  background-position: left bottom;
}

.mt-40 {
  margin-top: 40px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-80 {
  margin-top: 80px;
}

.mail-contact {
  font-size: 14px;
  color: white;
}

.section-wrapper {
  width: 100%;
  max-width: 1243px;
}

.contact-btn {
  background: #2b3243;
  color: white;
  padding: 14px 18px;
}

.main-color {
  color: #2B3243;
}

.divider {
  width: 100%;
  background: #bbc8d0;
  height: 2px;
  margin-top: 0;
  margin-bottom: 48px;
}

.hero-title {
  font-size: 42px;
  font-weight: 600;
  color: #2B3243;

  @media screen and (max-width:768px) {
    font-size: 30px;
  }
}


.hero-sm {
  font-size: 14px;
  text-align: right;

  @media screen and (max-width: 1024px) {
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}

.hero-sms {
  font-size: 20px;
  font-weight: 300;

  @media screen and (max-width: 1024px) {
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}

.mv-4 {
  @media screen and (max-width:524px) {
    margin: 60px 0;
  }
}