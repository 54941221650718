.cta-block{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #f0f0f0;
    border-radius: 4px;
    margin: 24px 0;
    box-sizing: content-box;
    padding: 24px;

    @media screen and (max-width:768px) {
      padding: 24px;
      text-align: center;
    }
  }