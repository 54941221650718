.showcase-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 600px;
  padding: 80px;

  .section-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 1243px;
    box-sizing: border-box;

    .img-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      margin-top: 48px;
      box-sizing: border-box;

      a:not(:last-child){
        margin-right: 24px;

        
      }

      @media screen and (max-width: 1280px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      @media screen and (max-width: 1160px) {
        grid-template-columns: 1fr 1fr 1fr;
      }

      @media screen and (max-width: 912px) {
        grid-template-columns: 1fr 1fr;
      }

      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
      }

      .row-showcase {
        transition: all 0.2s ease-in-out;
        width: 200px;

        @media screen and (max-width:1280px) {
          margin-bottom: 48px;
        }
      }

      .row-showcase:hover {
        transform: scale(1.03);
        transition: all 0.2s ease-in-out;
      }

      .row-showcase:not(:last-child) {
        margin-right: 48px;
        @media screen and (max-width: 1280px) {
          margin-right: 0;
          margin-bottom: 48px;
        }
      }
    }
  }
}

.mr-48 {
  margin-right: 0;
  @media screen and (max-width: 1280px) {
    margin-right: 48px !important;
  }

  @media screen and (max-width: 768px) {
    margin-right: 0 !important;
  }
}
