.quote-section {
  display: flex;
  min-height: 600px;
  justify-content: center;
  align-items: center;
  background: #bbc8d0;
  color: #fff;
  box-sizing: border-box;
  padding: 0 40px;

  @media screen and (max-width:912px) {
    padding: 60px 40px;
  }

  .big-absolute-wrapper {
    position: absolute;
    left: 0;
    margin-left: -120px;
    animation: rotate 24s linear infinite;
    transform-origin: 50% 50%;
    transform-box: fill-box;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  .sm-absolute-wrapper {
    position: absolute;
    left: 0;
    margin-left: -60px;
    animation: rotate-reverse 24s linear infinite;
    transform-origin: 50% 50%;
    transform-box: fill-box;
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes rotate-reverse {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }

  .section-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    max-width: 1000px;

    @media screen and (max-width: 1440px) {
      max-width: 900px;
    }

    @media screen and (max-width: 1200px) {
      max-width: 700px;
    }

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }

    .left-side {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      margin-right: 48px;

      @media screen and (max-width: 1024px) {
        align-items: center;
        margin-bottom: 48px;
        margin-right: 0;
        text-align: center;
      }
    }

    .right-side,
    form {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      width: 100%;

      @media screen and (max-width: 1024px) {
        align-items: center;
      }

      .formInput {
        display: flex;
        width: 100%;
        flex-direction: column;
        height: 60px;
        box-sizing: border-box;
        
        input {
          width: 100%;
          border: 0;
          outline: none;
          background: transparent;
          color: white;
          border-bottom: 1px solid white;
          
          @media screen and (max-width: 912px) {
            width: 100%;
          }
        }
        input:focus {
          outline: none;
          color: white;
        }
        input::placeholder {
          color: white;
        }
        input:invalid[focused="true"] {
          border-bottom: 1px solid red;
        }

        input:invalid[focused="true"] ~ span {
          font-size: 12px;
          color: red;
          display: block;
        }
      }
    }
    .submit-btn {
      border: none;
      width: 100%;
      outline: none;
      background: #0075ff;
      border-radius: 12px;
      height: 48px;
      color: white;
      cursor: pointer;
    }
  }

  .err-msg {
    font-size: 12px;
    padding-top: 3px;
    color: red;
    display: none;
    margin-bottom: 8px;
  }
}
