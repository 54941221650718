@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import 'locomotive-scroll/src/locomotive-scroll.scss';


* {

    font-family: 'Inter', sans-serif;

    ul {
        list-style: none;
        padding: initial;
    }

    a {
        text-decoration: none;
        color: inherit;
    }
}

body {
    padding: 0;
    margin: 0;
    background-color: white;
}