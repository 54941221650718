.accordion {
  min-height: 50px;
  border-bottom: 1px solid #bbc8d0;
  margin-bottom: 24px;
  width: 100%;
  .accordion-header {
    width: 100%;
    min-height: 32px;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 1.15rem;
    margin: 8px 0;
    color: #2e3035;

    .accordion-icon {
      display: flex;
      align-items: center;
      height: 100%;
      margin-right: 8px;
      span {
        width: 16px;
        height: 4px;
        transition: 0.1s ease-in-out;
        background: #2e3035;
      }
    }
  }

  .accordion-content {
    overflow: hidden;
    padding-left: 40px;
    span {
      width: 100%;
      margin: 8px 0;
      font-size: 0.875rem;
      font-weight: 300;
      color: #2e3035;
      display: block;
      font-weight: 300;
    }
  }
}

.accordion:hover {
  cursor: pointer;
}
