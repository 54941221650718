.showcase-list {
    position: relative;
    min-height: 82vh;
    max-height: 82vh;
    width: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;

    @media screen and (max-width:524px) {
        position: unset;
        overflow-y: unset;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        min-height: unset;
        max-height: unset;
    }
}

.showcase-page{
    display: flex;
    align-items: center;
    justify-content: center;
}

.showcase-list::-webkit-scrollbar{
    display: none;
}

.projects-list {
    width: 30vw;
    right: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-13%);
    height: fit-content;
    z-index: 1;
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);

    @media screen and (min-width:1440px) {
        transform: translateY(-18%);
        right: -10%;
    }

    @media screen and (max-width:912px) {
        transform: translateY(-21%);
    }

    @media screen and (max-width:524px) {
        width: 100%;
        transform: translateY(0%);
        position: fixed;
        top: 7%;
        padding: 40px;
        box-sizing: border-box;
    }

    img {
        object-fit: cover;
        width: 100%;
        height: 50vh;
        margin: 20px 0;

        @media screen and (min-width:1440px) {
            height: 40vh;
            width: 75%;
        }

        @media screen and (max-width:912px) {
            height: 34vh;
            width: 100%;
        }

        @media screen and (max-width:524px) {
            height: 40vh;
            width: 100%;
        }
    }
}

.projects-mobile-list{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    img{
        object-fit: cover;
        width: 240px;
        margin-bottom: 40px;
    }
}