.about-banner-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
  padding: 48px;

  .section-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 48px;
    width: 100%;

    .title-wrapper{
      width: 100%;
      margin-bottom: 24px;
    }

    .absolute-img {
      background: url("https://res.cloudinary.com/droimgtqi/image/upload/v1679865979/republica-textil/Group_34_waxigh.png");
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: cover;
      height: 300px;
      width: 100%;
    }

    .inner-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      box-sizing: border-box;

      .left-side {
        display: flex;
        width: 100%;
        flex-direction: column;
        box-sizing: border-box;
        margin-right: 24px;

        .main-image {
          width: 100%;
        }

        .other-image{
            background: url("https://res.cloudinary.com/droimgtqi/image/upload/v1679865977/republica-textil/Group_33_j8q52v.png");
            height: 400px;
            background-position: 100% 50%;
            background-repeat: no-repeat;
            background-size: 100%;
            margin-top: 48px;
        }
      }

      .right-side {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: 24px;

        .right-img{
            height: 540px;
            width: 100%;
            background: url("https://res.cloudinary.com/droimgtqi/image/upload/v1679865978/republica-textil/Group_32_gqejgo.png");
            background-position: center bottom;
            background-repeat: no-repeat;
        }
      }
    }
  }
}
