.about-section {
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  background: #000;
  padding: 10px 0;
  box-sizing: border-box;

  .top-content {
    color: #b6b6b6;
    padding-top: 10px;
    font-weight: 600;
    box-sizing: border-box;
  }

  .mid-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    color: #b6b6b6;
    margin: 150px 0;
    font-weight: bolder;
    box-sizing: border-box;
    padding: 0 48px;

    .section-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      max-width: 672px;
      text-align: center;
      box-sizing: border-box;
    }
  }

  .bottom-content {
    color: #b6b6b6;
    padding-bottom: 10px;
    font-weight: 600;
    box-sizing: border-box;
  }
}
