.services-page {
    display: flex;
    align-items: center;
    justify-content: center;

    .section-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .services-wrapper {
            width: 100%;
            margin-bottom: 180px;
            padding: 48px;
            box-sizing: border-box;

            .details-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;

                @media screen and (max-width:912px) {
                    flex-direction: column;
                    text-align: center;
                }

                .left-side {
                    margin: 0 18px;

                    @media screen and (max-width:912px) {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin: 0;
                        margin-bottom: 24px;
                    }
                }

                .right-side {
                    margin: 0 18px;

                    @media screen and (max-width:912px) {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin: 0;
                        margin-bottom: 24px;
                    }
                }

                .img-wrapper{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                }
            }

            .details-wrapper:not(:last-child) {
                margin-bottom: 140px;
            }
        }

        .img-service {
            width: 400px;

            @media screen and (max-width:912px) {
                width: 300px;
            }
        }

        .text-wrapper {
            max-width: 600px;

            @media screen and (max-width:1024px) {
                max-width: 400px;
            }

            .inner-text-wrapper{
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-direction: row;

                @media screen and (max-width:912px) {
                    flex-direction: column;
                }

                ul:not(:last-child){
                    margin-right: 24px;

                    @media screen and (max-width:912px) {
                        margin-right: 0;
                        margin: 0;
                    }
                }
            }
        }
    }

    .services-banner-section {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 48px;
        padding: 48px;

        .section-wrapper {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            padding: 48px;
            width: 100%;
        }
    }
}