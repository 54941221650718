.faqs-section {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-height: 700px;
  padding: 48px;
  box-sizing: border-box;

  @media screen and (max-width:768px) {
    padding: 24px;

  }

  .section-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 48px;
    box-sizing: border-box;

  }

  .faqs-header {
    margin-bottom: 24px;
    .faq-title {
      color: #2e3035;
      text-align: center;
      margin-bottom: 48px;
    }
  }

  .faqs-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 900px;
    width: 100%;
  }
}
