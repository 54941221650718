.hero-section {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin-bottom: 72px;
  min-height: 68vh;
  padding: 0 48px;

  @media screen and (max-width: 1024px) {
    margin-top: 0;
  }

  .section-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    box-sizing: border-box;
    flex-direction: column;
    margin: 24px 0;

    .top-banner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 18px;

      @media screen and (max-width: 1024px) {
        flex-direction: column;
        text-align: center;
      }

      .left-side {
        max-width: 700px;
      }

      .right-side {
        max-width: 400px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        @media screen and (max-width: 1024px) {
          align-items: center;
          justify-content: center;
          text-align: center;
        }
      }
    }

    .customers-wrapper {
      width: 100%;

      .inner-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 768px) {
          flex-direction: column;
        }

        .customer-img {
          width: 220px;
          transition: all ease-in-out 0.2s;

          @media screen and (max-width: 1024px) {
            width: 160px;
          }

          @media screen and (max-width: 768px) {
            width: 240px;
          }
        }

        .customer-img:hover {
          transform: scale(1.02);
          transition: all ease-in-out 0.2s;
        }

        .customer-img:not(:last-child) {
          @media screen and (max-width: 768px) {
            margin-bottom: 44px;
          }
        }
      }
    }
  }
}
