.main-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  background: #2e3035;
  color: rgb(206, 206, 206);
  padding-top: 40px;

  @media screen and (max-width: 768px) {
    padding: 40px 40px 0 40px;
  }

  .section-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    .business-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-left: auto;
      margin-right: auto;

      @media screen and (max-width: 768px) {
        margin-left: 0;
        margin-right: 0;
      }

      .logo-section {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 0.1em;
        margin-bottom: 8px;
        color: white;

        img {
          width: 48px;
          height: 48px;
          margin-right: 10px;
        }
      }

      .address-info {
        width: 100%;
        max-width: 220px;
        font-size: 12px;
        margin-top: 12px;

        @media screen and (max-width: 768px) {
          margin-bottom: 40px;
        }
      }
    }

    .navigation-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .navbar-section {
        display: flex;
        flex-direction: column;
      }
    }

    .footer-col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-left: auto;
      margin-right: auto;

      @media screen and (max-width: 768px) {
        margin-left: 0;
        margin-right: 0;
      }

      h3 {
        color: white;
        margin: 0 0 10px 0;
        padding: 0;
      }

      a {
        margin-bottom: 4px;
        font-size: 14px;
        transition: all 0.2s ease-in-out;
      }

      a::after {
        content: "";
        width: 0;
        height: 1px;
        display: block;
        background: rgb(206, 206, 206);
        transition: 300ms;
      }

      a:hover::after {
        width: 100%;
      }
    }

    .footer-col:not(:last-child) {
      @media screen and (max-width: 768px) {
        margin-bottom: 40px;
      }
    }
  }

  .copyright-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 12px;
    margin-top: 40px;
    text-align: center;
  }
}
