.projects-nav {
    position: fixed;
    mix-blend-mode: difference;
    width: fit-content;
    transform: scale(1);
    will-change: transform;
    transition: transform 1s cubic-bezier(0.86, 0, 0.07, 1) 0.08s;
    transform-origin: left;
    top:50%;
    transform: translateY(-50%);


    display: flex;
    flex-direction: column;
    justify-content: center;

    li {
        overflow: hidden;
        will-change: opacity;
        transition: opacity 0.4s ease;
        opacity: 0.24;
        visibility: inherit;
        font-size: 30px;
        font-weight: bold;


        a{
            @media screen and (max-width:524px) {
                width: 100%;
                display: inline;
            }
        }

        &:hover {
            opacity: 1;
        }
    }
}

.mobile-projects{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    mix-blend-mode: difference;
    width: fit-content;
    transform: scale(1);
    will-change: transform;
    transition: transform 1s cubic-bezier(0.86, 0, 0.07, 1) 0.08s;
    transform-origin: left;
    overflow-x: scroll;
    box-sizing: border-box;
    height: 20px;
    width: 100%;
    max-width: 82vw;
    margin-bottom: 48px;
    position: sticky;
    top: 1;
    left: 0;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;

    li{
        will-change: opacity;
        transition: opacity 0.4s ease;
        opacity: 0.24;
        visibility: inherit;
        font-size: 16px;
        font-weight: bold;
        a{
            @media screen and (max-width:524px) {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 121px;
            }
        }

        &:hover {
            opacity: 1;
        }
    }
    li:not(:last-child){
        margin-right: 20px;
    }
}

.mobile-projects::-webkit-scrollbar{
    display: none;
}